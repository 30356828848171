import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeColorsKeys } from '../../styles/tokens/colors';
import { ThemeSpaceKeys } from '../../styles/tokens/space';
import { useBackgroundColor } from '../../utils';
import { Stack } from '../stack/Stack';
import { SectionContextProvider } from '../../context/SectionContext';
import { Container } from '../container/Container';

// Interface

export interface SectionProps {
  children: any;
  as?: any;
  backgroundColor?: ThemeColorsKeys;
  narrow?: boolean;
  gap?: ThemeSpaceKeys;
}

type StyledSectionProps = Pick<SectionProps, 'backgroundColor'>;

// Styles

const StyledSection = styled.div<StyledSectionProps>`
  ${({ theme: { space }, backgroundColor }) => css`
    padding: ${space.fluidXxLarge} 0;
    overflow: hidden;

    // Background and color

    ${backgroundColor && useBackgroundColor(backgroundColor)};

    // Spacing between sibling sections

    &[data-background-color=${backgroundColor}] + [data-background-color=${backgroundColor}] {
      padding-top: 0;
    }
  `};
`;

// JSX

export const Section: FC<React.PropsWithChildren<SectionProps>> = ({
  as = 'section',
  children,
  backgroundColor = 'utilityWhite',
  narrow,
  gap = 'fluidXLarge',
  ...rest
}) => (
  <StyledSection
    backgroundColor={backgroundColor}
    data-background-color={backgroundColor}
    as={as}
    {...rest}
  >
    <SectionContextProvider value={{ sectionBackground: backgroundColor }}>
      <Container narrow={narrow}>
        <Stack gap={gap}>{children}</Stack>
      </Container>
    </SectionContextProvider>
  </StyledSection>
);

export default Section;
